import {mapActions, mapGetters, mapMutations} from "vuex";
import {debounce} from 'lodash'
import {formatCurrency} from "../../../../../utils/commons";
//sections
export default {
  name: "checkout-collection-row",
  components: {
  },
  props: {
    dress: {
      type: Object,
      default: ()=> {},
    }
  },
  data() {
    return {

    }
  },
  created() {
  },
  watch: {
    'dress.comment':{
      handler(newVal){
          this.changeComment({id: this.dress.id, payload: {comment: newVal}})
      }
    }
  },
  computed: {
    ...mapGetters({
      retailerDeleteDressLoading: 'collections/retailerDeleteDressLoading',
      profile: 'profile/user',
    }),

  },
  mounted() {

  },
  methods: {
    formatCurrency,
    ...mapActions({
      fetchProfile: 'profile/FETCH_USER_DATA',
      deleteDress: 'collections/DELETE_DRESS',
      updateComment: 'collections/CHANGE_COMMENT',
      fetchRetailerCart: 'collections/GET_RETAILER_CART',
    }),
    handleDeleteDress(){
      this.deleteDress({retail_dress_size_id: this.dress.id}).then(()=>{
        this.fetchRetailerCart()
        this.fetchProfile()
      })
    },
    changeComment: debounce(function (data) {
      this.updateComment({id: data.id, payload: data.payload})
    }, 300),
  }
}